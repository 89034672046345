/* reset.css */
* {
    padding: 0;
    margin: 0;
    border: none;
}

a,
a:link,
a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-size: inherit;
    font-weight: inherit;
}

ul,
ul li {
    list-style: none;
}

address {
    font-style: normal;
}

/* Form */
input,
textarea,
button,
select {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
}

button,
input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
    outline: none;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

label {
    cursor: pointer;
}

legend {
    display: block;
}


/* Убрать фон у автозаполненных полей */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset; /* Убрать фон */
  transition: background-color 5000s ease-in-out 0s;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: transparent !important; /* Убрать фон */
  background-image: none !important;
  color: #000 !important; /* Цвет текста */
}
