@font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-Heavy.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-HeavyItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-SemiboldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-Ultralight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../Fonts/SF-Pro-Display-UltralightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
  }
  