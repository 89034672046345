@import "./reset.css";
@import "./fonts.css";

.container {
  max-width: 1392px;
  margin: 0 auto;
  padding: 0 120px;
}

.navbar {
  position: absolute;
  top: 48px;
  width: 100%;
  z-index: 999;
}

.navbar__inner {
  /* max-width: 1200px; */
  width: 100%;
  display: flex;
  position: relative;
}

.navbar__content {
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 15px 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-news {
  background-color: rgba(247, 247, 247, 1);
}

.navbar-main {
  background-color: white; /* Background for main page */
}

.navbar__burger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.navbar__burger img {
  max-width: 25px;
  width: 100%;
}

.navbar__menu-container,
.navbar_right {
  display: flex;
  align-items: center;
  gap: 40px;
}

.navbar__menu-container.open,
.button-container {
  display: flex;
}

.navbar_left {
  max-height: 45px;
  height: 100%;
}

.navbar_right {
  max-height: 44px;
}

.navbar__menu {
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0;
}

.navbar__menu li {
  display: inline;
}

.navbar__menu a {
  text-decoration: none;
  font-family: "SF Pro Display";
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

/* Add this CSS to style.css */

/* Hover effect for navigation links */
.navbar__menu li a:hover {
  color: rgb(47, 87, 55); /* Change to your desired hover color */
  text-decoration: none;
}

/* Active link styling */
.navbar__menu li a.active {
  color: rgb(47, 87, 55); /* Change to your desired active color */
  font-weight: bold;
  text-decoration: none;
}


.navbar__button {
  background: linear-gradient(0deg, #2a4c31, #2a4c31),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 32px;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  line-height: 20px;
}

.navbar__button:hover {
  background-color: #244622;
}

/* Add this to your CSS file */

@media (max-width: 1015px) {
  .container {
    padding: 0 16px;
  }

  .projects-inner,
  .solutions,
  .activities,
  .consulting-services,
  .news, .services, .booking {
    padding: 100px 0;
  }

  .projects__content {
    gap: 20px;
  }

  .project-display {
    flex-direction: column;
    gap: 20px;
  }

  .project-image {
    max-width: 100%;
    max-height: 350px;
  }

  .projects__text {
    flex-direction: column;
    gap: 16px;
  }

  .project-details {
    margin-top: 20px;
  }

  .solutions__content {
    gap: 37px;
  }
  .solutions__text {
    flex-direction: column;
    gap: 16px;
  }

  .solutions__content p {
    margin-bottom: 20px;
  }

  .solutions__stats {
    gap: 10px;
  }

  .stat h3 {
    font-size: 48px;
    line-height: 60px;
  }

  .stat span {
    font-size: 16px;
    line-height: 20px;
  }

  .stat {
    max-width: 167px;
    width: 100%;
  }

  .stat span {
    font-size: 16px;
    line-height: 26px;
  }

  .activities_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }

  .consulting-content {
    gap: 20px;
  }

  .consulting-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }

  .trusted-by p,
  .booking_content h2,
  .text,
  .service-title,
  .service-title-details,
  .blog_title,
  .news_text_other {
    line-height: 31.2px;
    font-size: 24px;
  }

  .booking_inner {
    flex-direction: column-reverse;
    padding: 48px 0 0;
    gap: 57px;
  }

  .booking_content {
    flex: 1;
    color: #fff;
    padding: 0 19px;
  }

  .booking_image {
    width: 100%;
    max-height: 300px;
    height: 100%;
  }

  .news_content {
    align-items: center;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 56px;
  }

  .news_item {
    width: 100%;
    height: 300px;
  }

  .first_news {
    height: 300px;
  }

  .actovities_header,
  .solutions__content h2,
  .consulting-text h2,
  .projects__content h2,
  .footer__email,
  .hero__experience p {
    font-size: 36px;
    line-height: 46.8px;
  }

  .booking_content h2 {
    margin-bottom: 12px;
  }

  .booking_content p,
  .solutions__content p,
  .projects__content p,
  .activities__content_p,
  .consulting-text p {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1015px) {
  .project-display {
    gap: 64px;
  }

  .projects__content {
    gap: 65px;
  }

  .projects__text {
    flex-direction: row;
  }

  .projects__content h2 {
    font-size: 48px;
    line-height: 62.4px;
  }

  .solutions__content {
    gap: 64px;
  }

  .solutions__text {
    flex-direction: row;
    justify-content: space-between;
    gap: 137px;
  }

  .solutions__content p,
  .activities__content_p,
  .consulting-text p {
    font-size: 20px;
    line-height: 26px;
    max-width: 590px;
    width: 100%;
  }

  .solutions__stats {
    gap: 90px;
  }

  .stat h3 {
    font-size: 64px;
    line-height: 83.2px;
  }

  .stat span {
    font-size: 20px;
    line-height: 26px;
  }

  .stat {
    max-width: 188px;
    width: 100%;
  }

  .activities_inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 137px;
  }

  .consulting-services,
  .activities,
  .news,
  .solutions,
  .projects, .services, .booking  {
    padding: 150px 0;
  }

  .blog_title {
    font-size: 36px;
    line-height: 46.8px;
  }

  .consulting-content {
    gap: 40px;
  }

  .news_text_other {
    font-size: 36px;
    line-height: 46.8px;
  }

  .consulting-text {
    display: flex;
    flex-direction: row;
    gap: 137px;
  }

  .trusted-by p {
    max-width: 285px;
    width: 100%;
  }

  .booking_image {
    max-width: 545px;
    width: 100%;
  }

  .booking_content {
    max-width: 443px;
    width: 100%;
    padding: 102px 114px 98px 102px;
  }

  .news_content {
    gap: 20px;
  }

  .news_item {
    width: calc(33% - 10px);
  }

  .projects__content p {
    max-width: 590px;
    font-size: 20px;
    line-height: 26px;
  }

  .activities__content {
    max-width: 590px;
    width: 100%;
  }

  .projects-list {
    max-width: 590px;
  }

  .project-image {
    max-width: 545px;
    max-height: 352px;
  }

  .logos {
    padding: 16.21px 0;
    max-height: 61.59px;
  }

  .actovities_header,
  .solutions__content h2,
  .consulting-text h2 {
    max-width: 473px;
    font-size: 48px;
    line-height: 62.4px;
  }

  .service-title,
  .service-title-details,
  .booking_content h2,
  .trusted-by p {
    line-height: 46.8px;
    font-size: 36px;
  }

  .booking_content h2 {
    margin-bottom: 12px;
  }

  .booking_content p {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 44px;
  }
}

@media (max-width: 1140px) {
  .trusted-by {
    flex-direction: column;
  }
}

@media (min-width: 1140px) {
  .trusted-by {
    flex-direction: row;
  }
}

.hero {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0 0 24px 24px;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.hero_inner {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 258px;
}

.hero__content {
  z-index: 1;
  width: 100%;
}

.hero_main_text {
  display: flex;
  align-items: center;
  z-index: 1;
}

.hero_text_p {
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
}

.hero_text_h1 {
  font-family: "SF Pro Display";
  font-weight: 700;
}

.hero_textfull_p {
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  max-width: 660px;
  width: 100%;
  margin-top: 20px;
}

.hero__experience {
  display: flex;
  align-items: center;
}

.hero_counter {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 40px;
}

.hero_counter p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
}

.hero__experience p {
  font-family: "SF Pro Display";
  font-weight: 500;
  text-align: left;
}

.hero__experience span {
  font-size: 2rem;
  font-weight: bold;
}

.hero_buttonpart {
  display: flex;
  justify-content: end;
  align-items: end;
  width: 100%;
  height: 100%;
}

.hero__button {
  background-color: transparent;
  color: white;
  padding: 12px 24px;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid rgba(255, 253, 245, 1);
  max-width: 222px;
  max-height: 44px;
}

.hero__button img {
  width: 20px;
  height: 20px;
}

.hero__button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* CIRCLE */

.outer-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 0.1px solid rgba(255, 253, 245, 0.17);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.inner-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid rgba(255, 253, 245, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.text {
  font-family: "SF Pro Display";
  font-weight: 500;
  color: white; /* Adjust text color as needed */
}

.dot {
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  position: absolute;
  transition: top 0.1s linear, left 0.1s linear;
}

@media (max-width: 800px) {
  .navbar__content {
    flex-direction: row;
    align-items: center;
    padding: 15px 20px;
  }

  .navbar_left img {
    max-width: 150px;
    max-height: 37.63px;
    width: 100%;
    height: 100%;
  }

  .navbar_right {
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
    max-height: 100%;
  }

  .navbar__menu {
    flex-direction: column;
    gap: 10px;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
  }

  .navbar__button {
    display: none;
  }

  .navbar__menu-container {
    display: none;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .navbar__menu-container.open {
    display: flex;
  }

  .navbar__menu li {
    margin: 10px 0;
  }

  .navbar__burger {
    display: block;
  }

  .hero_inner {
    padding: 187px 0 25px;
  }

  .hero_main_text {
    justify-content: space-between;
    flex-direction: column;
    gap: 58px;
  }

  .hero_counter p {
    display: none;
  }

  .hero_buttonpart {
    display: none;
  }

  .hero__experience {
    display: none;
  }

  .hero_text_h1 {
    font-size: 48px;
  }

  .hero_textfull_p {
    margin: 12px 0 20px;
  }

  .hero__button_mobile {
    background-color: #2a4c31;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 32px;
    font-family: "SF Pro Display";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }

  .block__content {
    margin-bottom: 100px;
  }
}

@media (min-width: 800px) {
  .hero_inner {
    padding: 324px 0 70px;
  }

  .hero_main_text {
    justify-content: space-between;
    flex-direction: row;
  }

  .hero_textfull_p {
    margin-top: 20px;
  }

  .hero__experience {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hero_text_h1 {
    font-size: 72px;
  }

  .hero__button_mobile {
    display: none;
  }

  .block__content {
    margin-bottom: 180px;
    margin-left: auto; /* Push the content to the right */
  }
}

.projects {
  background-color: #fff;
}

.projects-inner {
  display: flex;
  flex-direction: column;
}

.projects__content {
  display: flex;
  text-align: left;
  flex-direction: column;
}

.projects__text {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.projects__content h2 {
  font-family: "SF Pro Display";
  font-weight: 500;
}

.projects__content p {
  width: 100%;
  font-family: "SF Pro Display";
  font-weight: 400;
}

.project-display {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.project-image {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 24px;
}

.projects-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
}

.project-item {
  border-top: 1px solid rgba(185, 185, 185, 1);
  padding: 33.5px 0;
}

.project-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.project-details {
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  overflow: hidden;
  white-space: pre-line; /* Позволяет переносу строк */
  margin-top: 20px;
}

/* CONSULTING */

.consulting-services {
  background-color: #fff;
}

.consulting-content {
  display: flex;
  flex-direction: column;
}

.consulting-text h2 {
  font-family: "SF Pro Display";
  font-weight: 500;
  text-align: left;
  width: 100%;
}

.consulting-image img {
  width: 100%;
  max-height: 562px;
  height: 100%;
  border-radius: 24px;
}

.trusted-by {
  display: flex;
  text-align: left;
  gap: 20px;
}

.trusted-by p {
  font-family: "SF Pro Display";
  font-weight: 500;
  text-align: left;
}

.logos {
  overflow: hidden;
  white-space: nowrap;
}

.logo-container {
  display: inline-flex;
  align-items: center;
  animation: slide 20s linear infinite;
}

.logos img {
  margin: 0 35px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* SOLUTIONS */

.solutions {
  background-color: #ffffff;
}

.solutions__text {
  display: flex;
  align-items: flex-start;
}

.solutions__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.solutions__content h2 {
  width: 100%;
  font-family: "SF Pro Display";
  font-weight: 500;
}

.solutions__content p,
.consulting-text p,
.activities__content_p {
  font-family: "SF Pro Display";
  font-weight: 400;
  text-align: left;
  width: 100%;
}

.solutions__stats {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.stat {
  text-align: left;
  color: rgba(4, 4, 4, 1);
  border-top: 1px solid rgba(4, 4, 4, 1);
}

.stat h3 {
  font-family: "SF Pro Display";
  font-weight: 500;
  margin-bottom: 12px;
}

.stat span {
  font-family: "SF Pro Display";
  font-weight: 400;
  text-align: left;
}

/* SERVICES */
/* Services.css */
.services {
  overflow: auto; /* Скрыть скроллинг */
  white-space: nowrap;
  background: linear-gradient(0deg, #2a4c31, #2a4c31),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.services .container {
  width: 100%;
  /* other styles as needed */
}

.services_inner {
  display: flex;
  width: max-content;
  justify-content: center; /* Центрировать содержимое */
  align-items: stretch;
}

.services__content {
  display: flex;
  flex-direction: row;
  max-width: 100%; /* Ограничить ширину контейнера */
  overflow-x: auto; /* Добавить горизонтальный скролл только для содержимого */
  transition: transform 0.5s ease; /* Плавный скроллинг */
}

/* Hide scrollbar for all browsers */
.services {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
  overflow-x: scroll;
}

/* For Webkit browsers (Chrome, Safari, and Opera) */
.services::-webkit-scrollbar {
  display: none;
}

/* For Microsoft Edge */
@supports (-ms-overflow-style: none) {
  .services__content {
    -ms-overflow-style: none; /* IE and Edge */
  }
}

.service-item {
  position: relative;
  flex: 0 0 auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  transition: all 0.5s ease;
}

.service-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 70.04%
  );
  
  border-radius: 24px;
  pointer-events: none; /* To ensure the gradient does not block clicks */
}

.service-item img {
  object-fit: cover;
  width: 100%;
  height: inherit;
  display: block;
  transition: transform 0.5s ease;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 70.04%
  );
  
  border-radius: 24px;
}

.service-item .service-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 46%
  );
  color: white;
  opacity: 0;
  transition: opacity 0.1s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.service-title {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  padding: 24px;
  z-index: 10;
  margin: 0;
  width: 100%;
  text-align: left;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  overflow: hidden;
  white-space: normal;
}

.service-title-details {
  color: white;
  padding-bottom: 10px;
  z-index: 10;
  margin: 0;
  width: 100%;
  text-align: left;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  overflow: hidden;
  white-space: normal;
}

.service-details {
  margin: 0;
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  white-space: normal; /* Ensures newlines are respected */
}

.service-item:hover img {
  transform: scale(1.1);
  border-radius: 24px;
}

.service-item:hover .service-text {
  opacity: 1;
}

.service-item:hover .service-title {
  display: none;
}

.service-item:hover .service-details {
  opacity: 1;
}

/* ACTIVITIES */

.activities {
  background: linear-gradient(0deg, #2a4c31, #2a4c31),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  color: white;
}

.activities_inner {
  display: flex;
  align-items: flex-start;
}

.actovities_header {
  font-family: "SF Pro Display";
  font-weight: 500;
  text-align: left;
  width: 100%;
}

.activities__content {
  width: 100%;
}

.activities__content_p {
  color: rgba(255, 253, 245, 1);
}

/* BOOKING */

.booking {
  background-color: #fff;
}

.booking_inner {
  display: flex;
  align-items: center;
  background-color: rgba(42, 76, 49, 1);
  border-radius: 30px;
  overflow: hidden;
}

.booking_image img {
  width: 100%;
  height: auto;
}

.booking_content {
  flex: 1;
}

.booking_content h2 {
  font-family: "SF Pro Display";
  font-weight: 500;
  text-align: left;
  color: #fff;
}

.booking_content p {
  font-family: "SF Pro Display";
  font-weight: 400;
  text-align: left;
  margin-bottom: 44px;
  color: #fff;
}

.booking_form {
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(255, 253, 245, 1);
}

.booking_form input {
  flex: 1;
  padding: 0 0 12px;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  border: none;
  background: transparent;
  outline: none;
  color: #000;
}

.booking_form input::placeholder {
  color: white; /* Цвет для placeholder */
  background-color: rgba(42, 76, 49, 1);
}

.booking_form button {
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 28px;
  cursor: pointer;
  transition: color 0.3s;
}

/* NEWS */
.news {
  background-color: #fff; /* Change as needed */
}

.news_header {
  font-family: "SF Pro Display";
  font-size: 48px;
  font-weight: 500;
  line-height: 62.4px;
  text-align: left;
  margin-bottom: 24px;
}

.news_content {
  display: flex;
  justify-content: space-between;
}

.news_item {
  position: relative;
  overflow: hidden;
  border-radius: 24px; /* Adjust border radius as needed */
  text-decoration: none;
  color: inherit;
}

.news_photo {
  width: 100%;
  height: 299px; /* Adjust height as needed */
  background-size: cover;
  background-position: center;
  object-fit: cover;
  object-position: center;
}

.news_text_other {
  position: absolute; /* Absolute positioning for the text */
  bottom: 20px; /* Position from the bottom */
  left: 20px; /* Position from the left */
  color: rgba(42, 76, 49, 1);
  padding: 5px 10px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
  font-family: "SF Pro Display";
  font-weight: 600;
  text-align: left;
}

.news_title {
  position: absolute; /* Absolute positioning for the text */
  bottom: 20px; /* Position from the bottom */
  left: 20px; /* Position from the left */
  color: white; /* Text color */
  padding: 5px 10px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
  font-family: "SF Pro Display";
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.first_news {
  border: 1px solid #2a4c31;
  cursor: pointer;
  transition: all 0.3s ease;
}

.news_button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #000; /* Button background */
  color: #fff; /* Button text color */
  border: none;
  cursor: pointer;
  outline: none;
}

.arrow_right {
  margin-left: 5px;
}

/* Footer */

.footer {
  background-color: rgba(42, 76, 49, 1);
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  color: #fff;
}

.footer__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  padding: 44px 0 77px;
}

.footer__socials {
  display: flex;
  gap: 10px;
}

.footer__icon {
  width: 24px;
  height: 24px;
}

.footer__info {
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  margin: 5px 0;
}

.footer__email {
  font-family: "SF Pro Display";
  font-weight: 600;
  text-align: left;
}

.footer__right {
  max-width: 77px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer__right a {
  font-family: "SF Pro Display";
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-bottom: 12px;
  color: #fff;
}

.footer__right a:hover {
  text-decoration: underline;
}

.blog_header {
  font-family: "SF Pro Display", sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 62.4px;
  margin-bottom: 32px;
}

.latest_post {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 62px;
}

.post_image {
  width: 100%;
  max-height: 406px;
  height: 100%;
}

.post_content {
  display: flex;
  justify-content: space-between;
}

.post_title,
.row_post_title {
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;
  text-align: left;
}

.post_body,
.row_post_body {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  margin-bottom: 10px;
  text-align: left;
  max-width: 422px;
  width: 100%;
  margin-bottom: 20px;
  color: rgba(134, 134, 134, 1);
}

.post_footer,
.row_post_footer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  color: rgba(4, 4, 4, 1);
}

.author_image {
  max-width: 20px;
  width: 100%;
  max-height: 20px;
  height: 100%;
}

.author_name,
.post_date {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
}

.dot_main {
  background-color: #000;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}

.other_posts {
  display: flex;
  flex-direction: column;
}

.post_row {
  display: flex;
  flex-direction: row;
  padding: 32px 0;
  align-items: center;
  gap: 20px;
}

.row_post_content {
  flex: 1;
}

.read_more_button {
  margin-left: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.arrow_link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  text-decoration: none;
}

.row_post_image {
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
}

.header_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog_title {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}

.post_details {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  gap: 15px;
}

.author_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.blog__image {
  max-height: 409px;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.block__content {
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  max-width: 793px;
  width: 100%;
  font-weight: 400;
  text-align: left; /* Align content to the right */
}

@media (max-width: 915px) {
  .post_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .post_row {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .read_more_button {
    display: none;
  }

  .blog_section {
    margin: 180px 0 100px;
  }

  .header_title {
    align-items: start;
  }

  .footer__inner {
    flex-direction: column;
    gap: 48px;
  }

  .footer__left {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .footer__center {
    width: 100%;
  }

  .footer__logo {
    width: 80px;
    height: auto;
  }

  .row_post_image {
    width: 100%;
    height: 223px;
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
  }

  .row_post_content {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin: 0;
  }

  .services__content {
    gap: 8px;
  }

  .service-item {
    max-width: 300px;
    max-height: 400px;
  }
}

@media (min-width: 915px) {
  .post_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .row_post_image {
    width: 390px;
    height: 223px;
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
  }

  .post_row {
    border-top: 1px solid rgba(185, 185, 185, 1);
  }

  .blog_section {
    margin: 184px 0;
  }

  .header_title {
    align-items: center;
  }

  .footer__left {
    max-width: 182px;
    width: 100%;
  }

  .footer__center {
    max-width: 360px;
    width: 100%;
  }

  .footer__logo {
    width: 50px;
    height: auto;
  }

  .footer__socials {
    margin-top: 32px;
  }

  .footer__email {
    font-size: 40px;
    line-height: 47.73px;
  }

  .services__content {
    gap: 16px;
  }

  .service-item {
    max-width: 444px;
    max-height: 470px;
  }
}

/* Стили для ссылок внутри компонента Blog */
.blog_section a {
  color: #333; /* Цвет текста ссылок по умолчанию */
  text-decoration: none; /* Убираем подчеркивание */
}

.blog_section a:hover {
  color: #244622; /* Цвет текста ссылок при наведении */
}

.blog_section a:active {
  color: #244622; /* Цвет текста ссылок при нажатии */
}

/* Loading Spinner Styles */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #244622; /* Blue color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Error Page Styles */
.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SF Pro Display";
  height: 120vh;
  background: linear-gradient(to right, #ece9e6, #ffffff);
  flex-direction: column;
}

.error-content {
  text-align: center;
}

.error-title {
  font-size: 10rem;
  font-weight: bold;
  margin: 0;
  color: #244622;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.error-message {
  font-size: 1.5rem;
  margin: 10px 0 40px;
  color: #333;
}

.error-button {
  padding: 20px 50px;
  border-radius: 10%;
  background-color: #244622;
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.error-button:hover {
  background-color: white;
  color: #244622;
  border: 2px solid #244622;
}
